import React, { useEffect, useState } from "react";
import Footer from "@organism/Footer/Footer";
import { navigate } from "gatsby";

import Navbar from "@organism/Navbar/Navbar";
import ApplyForm from "@organism/ApplyForm";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { isBrowser } from "@utils/isBrowser";
import PipeDriveChat from "@utils/pipedriveChat";
// import CustomCursor from "@atoms/CustomCursor";

const Apply = ({ location }) => {
  // console.log('LOCATION => ', location)
  const { t } = useTranslation();
  const [chatStyle, setChatStyle] = useState(`textarea[class^="inputField_"]
  {
    color: #FFFFFF !important; 
     -webkit-text-fill-color: #FFFFFF !important;
  }`);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);

  const handleOnLoad = () => {
    setTimeout(() => {
      setChatStyle(`textarea[class^="inputField_"]
      {
        color:white!important; 
         -webkit-text-fill-color: white !important;
      } `);
    }, 2500)
  };
  const openChat = () => {
    setinitChat(true);

    setClientChat(t("JIVOCHAT"));
    handleOnLoad();
  };

  useEffect(() => {
    if (initChat) {
      if (
        isBrowser &&
        clientChat !== false &&
        clientChat !== t("JIVOCHAT")
      ) {
        window.location.reload();
      }
    }
  });
  useEffect(() => {
    if (!location.state) {
      navigate("/work");
    }
  }, []);

  return (
    <>
      {/* <CustomCursor /> */}
      <Navbar location={location} />
      {location.state && location.state.item && (
        <>
          <ApplyForm item={location.state.item} />
          {/* <Chat
            chatStyle={chatStyle}
            clientChat={clientChat}
            initChat={initChat}
            openChat={openChat}
          /> */}
          <PipeDriveChat />
          <Footer openChat={openChat} />
        </>
      )}
    </>
  );
};

export default Apply;
