import React from "react";
import Form from "@molecules/Form";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import styles from "./apply-form.module.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

const ApplyForm = ({ item }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <section className={`${styles.section}`}>
      {item && (
        <>
          <p className={styles.apply}>{t("WORK.APPLYINGFOR")}</p>
          <h1 className={styles.title}>
            {item.job_translations[language]?.name}
          </h1>
          <h2 className={styles.locationAndTime}>
            {item.location.name} - {item.job_type.type}
          </h2>
          <p className={styles.description}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {item.job_translations[language]?.description}
            </ReactMarkdown>          
          </p>
        </>
      )}
      <Form job={item.job_translations[language]?.name} />
    </section>
  );
};

export default ApplyForm;
